// Path: ./src/locales/messages.ts
/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from './translations';
import { _t } from 'utils/messages';

export const messages = {
  Closed: () => _t(translations.Closed),
  Open: () => _t(translations.Open),
  Distance: () => _t(translations.Distance),
  Google_Reviews: () => _t(translations.Google_Reviews),
  mi: () => _t(translations.mi),
  km: () => _t(translations.km),
  Add_To_Shopping_List: () => _t(translations.Add_To_Shopping_List),
  On_Google_Maps: () => _t(translations.On_Google_Maps),
  View_All_Deals: () => _t(translations.View_All_Deals),
  Weekly_Flyer: () => _t(translations.Weekly_Flyer),
  Search: () => _t(translations.Search),
  Dont_Miss_Out: () => _t(translations.Dont_Miss_Out),
  Your_Shopping_List_Is_Empty: () =>
    _t(translations.Your_Shopping_List_Is_Empty),
  Add_deals_to_it_redeem_them_in_store: () =>
    _t(translations.Add_deals_to_it_redeem_them_in_store),
  Spots_Left: () => _t(translations.Spots_Left),
  Get_Exclusive_Deals: () => _t(translations.Get_Exclusive_Deals),
  Receive_the_best_promotions_available_right_within_your_SMS_inbox: () =>
    _t(
      translations.Receive_the_best_promotions_available_right_within_your_SMS_inbox,
    ),
  Phone_Number: () => _t(translations.Phone_Number),
  Email: () => _t(translations.Email),
  Logout: () => _t(translations.Logout),
  Name: () => _t(translations.Name),
  Subscribe: () => _t(translations.Subscribe),
  Thanks_for_subscribing: () => _t(translations.Thanks_for_subscribing),
  Deals_Available: () => _t(translations.Deals_Available),
  Deals: () => _t(translations.Deals),
  Stores: () => _t(translations.Stores),
  Shopping_List: () => _t(translations.Shopping_List),
  Favorites: () => _t(translations.Favorites),
  Explore: () => _t(translations.Explore),
  Best_Deals: () => _t(translations.Best_Deals),
  Closest: () => _t(translations.Closest),
  Day_Left: () => _t(translations.Day_Left),
  Expire_Today: () => _t(translations.Expire_Today),
  Save: () => _t(translations.Save),
  Back: () => _t(translations.Back),
  View: () => _t(translations.View),
  Deals_From_Same_Store: () => _t(translations.Deals_From_Same_Store),
  Absolutely_Loving_The_App: () => _t(translations.Absolutely_Loving_The_App),
  Good_But_Could_Be_Improved: () => _t(translations.Good_But_Could_Be_Improved),
  Some_Issues_Id_Like_To_Talk_About: () =>
    _t(translations.Some_Issues_Id_Like_To_Talk_About),
  Not_Really_Good: () => _t(translations.Not_Really_Good),
  Submit_Feedback: () => _t(translations.Submit_Feedback),
  FEEDBACK: () => _t(translations.FEEDBACK),
  How_is_your_experience_so_far: () =>
    _t(translations.How_is_your_experience_so_far),
  Thanks_for_your_feedback: () => _t(translations.Thanks_for_your_feedback),
  Submit: () => _t(translations.Submit),
  Tell_us_why: () => _t(translations.Tell_us_why),
  Redeem_go_to_store: () => _t(translations.Redeem_go_to_store),
  In_shopping_list: () => _t(translations.In_shopping_list),
  Starts_Tomorrow: () => _t(translations.Starts_Tomorrow),
  Banner_Text: () => _t(translations.Banner_Text),
  Contact_Store: () => _t(translations.Contact_Store),
  Call_Us: () => _t(translations.Call_Us),
  Stay_Tuned: () => _t(translations.Stay_Tuned),
  Get_App: () => _t(translations.Get_App),
  Recent_Searches: () => _t(translations.Recent_Searches),
  Trending_Searches: () => _t(translations.Trending_Searches),
  Trending_Cities: () => _t(translations.Trending_Cities),
  restaurant: () => _t(translations.restaurant),
  All_Flyers: () => _t(translations.All_Flyers),
  Flyers: () => _t(translations.Flyers),
  Favourites: () => _t(translations.Favourites),
  grocery: () => _t(translations.grocery),
  chinesesupermarket: () => _t(translations.chinesesupermarket),
  electricalAppliances: () => _t(translations.electricalAppliances),
  About_Us: () => _t(translations.About_Us),
  Mobile_App: () => _t(translations.Mobile_App),
  FAQ_Help: () => _t(translations.FAQ_Help),
  Give_Some_Feedback: () => _t(translations.Give_Some_Feedback),
  Terms_of_Use: () => _t(translations.Terms_of_Use),
  Privacy_Policy: () => _t(translations.Privacy_Policy),
  Flyer_Deals: () => _t(translations.Flyer_Deals),
  Reviews: () => _t(translations.Reviews),
  InfoStores: () => _t(translations.InfoStores),
  Mobile: () => _t(translations.Mobile),
  Start_Saving_Today: () => _t(translations.Start_Saving_Today),
  Start_Saving: () => _t(translations.Start_Saving),
  Info_TopContent_Title: () => _t(translations.Info_TopContent_Title),
  Info_TopContent_Subtitle: () => _t(translations.Info_TopContent_Subtitle),
  Info_ContentOne_Title: () => _t(translations.Info_ContentOne_Title),
  Info_ContentOne_Subtitle: () => _t(translations.Info_ContentOne_Subtitle),
  Check_Out_The_latest_Flyers: () =>
    _t(translations.Check_Out_The_latest_Flyers),
  Info_ContentTwo_Title: () => _t(translations.Info_ContentTwo_Title),
  Info_ContentTwo_Subtitle: () => _t(translations.Info_ContentTwo_Subtitle),
  Browse_Exclusive_Deals: () => _t(translations.Browse_Exclusive_Deals),
  Info_ContentThree_Title: () => _t(translations.Info_ContentThree_Title),
  Info_ContentThree_Subtitle: () => _t(translations.Info_ContentThree_Subtitle),
  See_Available_Stores: () => _t(translations.See_Available_Stores),
  Info_ContentFourth_Title: () => _t(translations.Info_ContentFourth_Title),
  Info_ContentFourth_Subtitle: () =>
    _t(translations.Info_ContentFourth_Subtitle),
  Start_Browsing_Deals: () => _t(translations.Start_Browsing_Deals),
  SEE_WHAT_OTHERS_HAVE_TO_SAY: () =>
    _t(translations.SEE_WHAT_OTHERS_HAVE_TO_SAY),
  I_simply_cant_shop_without_the_GoFlyer_app_anymore: () =>
    _t(translations.I_simply_cant_shop_without_the_GoFlyer_app_anymore),
  My_wife_calculated_that_weve_saved_34550_so_far: () =>
    _t(translations.My_wife_calculated_that_weve_saved_34550_so_far),
  The_app_made_me_discover_my_new_goto_stores: () =>
    _t(translations.The_app_made_me_discover_my_new_goto_stores),
  Downtown_Toronto: () => _t(translations.Downtown_Toronto),
  North_York: () => _t(translations.North_York),
  Etobicoke: () => _t(translations.Etobicoke),
  BROWSE_GoFlyer_START_SAVING_NOW: () =>
    _t(translations.BROWSE_GoFlyer_START_SAVING_NOW),
  On_Your_iPhone: () => _t(translations.On_Your_iPhone),
  On_Your_Computer: () => _t(translations.On_Your_Computer),
  On_Your_Android_Phone: () => _t(translations.On_Your_Android_Phone),
  Have_any_questions: () => _t(translations.Have_any_questions),
  Contact_Us: () => _t(translations.Contact_Us),
  Who_we_are: () => _t(translations.Who_we_are),
  Products: () => _t(translations.Products),
  Mobile_APP: () => _t(translations.Mobile_APP),
  Legal: () => _t(translations.Legal),
  Careers: () => _t(translations.Careers),
  All_rights_reserved: () => _t(translations.All_rights_reserved),
  Sign_In: () => _t(translations.Sign_In),
  Profile: () => _t(translations.Profile),
  Featured: () => _t(translations.Featured),
  Latest: () => _t(translations.Latest),
  Grocery: () => _t(translations.Grocery),
  Original_Price: () => _t(translations.Original_Price),
  Store_Price: () => _t(translations.Store_Price),
  Matchup_At: () => _t(translations.Matchup_At),
  Favorite_Empty_Title: () => _t(translations.Favorite_Empty_Title),
  Favorite_Empty_Click: () => _t(translations.Favorite_Empty_Click),
  Favorite_Empty_Description1: () =>
    _t(translations.Favorite_Empty_Description1),
  Favorite_Empty_Description2: () =>
    _t(translations.Favorite_Empty_Description2),
  Favorite_Empty_Button: () => _t(translations.Favorite_Empty_Button),
  Download_Goflyer: () => _t(translations.Download_Goflyer),
  Download_Goflyer_Description1: () =>
    _t(translations.Download_Goflyer_Description1),
  Download_Goflyer_Description2: () =>
    _t(translations.Download_Goflyer_Description2),
  Valid: () => _t(translations.Valid),
  See_More_Product: () => _t(translations.See_More_Product),
  Merchants: () => _t(translations.Merchants),
  Search_Flyers_or_Items: () => _t(translations.Search_Flyers_or_Items),
  Items: () => _t(translations.Items),
  UpComing: () => _t(translations.UpComing),
  Click_To_See_Flyer: () => _t(translations.Click_To_See_Flyer),
  THERE_ARE_NO_UPCOMING_FLYER_STAY_TUNED: () =>
    _t(translations.THERE_ARE_NO_UPCOMING_FLYER_STAY_TUNED),
  CLICK_TO_SEE_MORE_FLYERS: () => _t(translations.CLICK_TO_SEE_MORE_FLYERS),
  Starting_On: () => _t(translations.Starting_On),

  THERE_ARE_NO_FLYERS: () => _t(translations.THERE_ARE_NO_FLYERS),
  STAY_TUNED: () => _t(translations.STAY_TUNED),
  IN_THIS_CATEGORY: () => _t(translations.IN_THIS_CATEGORY),
  THERE_ARE_NO_LATEST_FLYERS: () => _t(translations.THERE_ARE_NO_LATEST_FLYERS),
  THERE_ARE_NO_DEALS: () => _t(translations.THERE_ARE_NO_DEALS),
  Promotion_Detail: () => _t(translations.Promotion_Detail),
  Store_Products: () => _t(translations.Store_Products),
  Store_Not_Found: () => _t(translations.Store_Not_Found),
  No_Flyer_Or_Promotion_Against_Store: () =>
    _t(translations.No_Flyer_Or_Promotion_Against_Store),
  Exclusive_Deal: () => _t(translations.Exclusive_Deal),
  Map_View: () => _t(translations.Map_View),
  Add_To_Cart_Shopping_List: () => _t(translations.Add_To_Cart_Shopping_List),
  homeDescription: () => _t(translations.homeDescription),
  homeKeywords: () => _t(translations.homeKeywords),
  privacyPolicyDescription: () => _t(translations.privacyPolicyDescription),
  privacyPolicyKeywords: () => _t(translations.privacyPolicyKeywords),
  termsOfUseDescription: () => _t(translations.termsOfUseDescription),
  termsOfUseKeywords: () => _t(translations.termsOfUseKeywords),
  aboutDescription: () => _t(translations.aboutDescription),
  aboutKeywords: () => _t(translations.aboutKeywords),
  flyersDescription: () => _t(translations.flyersDescription),
  flyersKeywords: () => _t(translations.flyersKeywords),
  flyerTagDescription: () => _t(translations.flyerTagDescription),
  flyerTagKeywords: () => _t(translations.flyerTagKeywords),
  promotionListDescription: () => _t(translations.promotionListDescription),
  promotionListKeywords: () => _t(translations.promotionListKeywords),
  promotionDetailIdDescription: () =>
    _t(translations.promotionDetailIdDescription),
  promotionDetailIdKeywords: () => _t(translations.promotionDetailIdKeywords),
  promotionDetailDescription: () => _t(translations.promotionDetailDescription),
  promotionDetailKeywords: () => _t(translations.promotionDetailKeywords),
  shoppingListDescription: () => _t(translations.shoppingListDescription),
  shoppingListKeywords: () => _t(translations.shoppingListKeywords),
  favouritesDescription: () => _t(translations.favouritesDescription),
  favouritesKeywords: () => _t(translations.favouritesKeywords),
  allFlyersDescription: () => _t(translations.allFlyersDescription),
  allFlyersKeywords: () => _t(translations.allFlyersKeywords),
  latestDescription: () => _t(translations.latestDescription),
  latestKeywords: () => _t(translations.latestKeywords),
  upcomingDescription: () => _t(translations.upcomingDescription),
  upcomingKeywords: () => _t(translations.upcomingKeywords),
  restaurantsDescription: () => _t(translations.restaurantsDescription),
  restaurantsKeywords: () => _t(translations.restaurantsKeywords),
  restaurantViewIdDescription: () =>
    _t(translations.restaurantViewIdDescription),
  restaurantViewIdKeywords: () => _t(translations.restaurantViewIdKeywords),
  flyerViewIdDescription: () => _t(translations.flyerViewIdDescription),
  flyerViewIdKeywords: () => _t(translations.flyerViewIdKeywords),
  searchValueDescription: () => _t(translations.searchValueDescription),
  searchValueKeywords: () => _t(translations.searchValueKeywords),
  signInDescription: () => _t(translations.signInDescription),
  signInKeywords: () => _t(translations.signInKeywords),
  groceryDescription: () => _t(translations.groceryDescription),
  groceryKeywords: () => _t(translations.groceryKeywords),
  restaurantDescription: () => _t(translations.restaurantDescription),
  restaurantKeywords: () => _t(translations.restaurantKeywords),
  chineseSupermarketDescription: () =>
    _t(translations.chineseSupermarketDescription),
  chineseSupermarketKeywords: () => _t(translations.chineseSupermarketKeywords),
  electricalAppliancesDescription: () =>
    _t(translations.electricalAppliancesDescription),
  electricalAppliancesKeywords: () =>
    _t(translations.electricalAppliancesKeywords),
  emailFormatInvalid: () => _t(translations.emailFormatInvalid),
  enterValidEmailRequired: () => _t(translations.enterValidEmailRequired),
  Edit: () => _t(translations.Edit),
  Done: () => _t(translations.Done),
  confirmAccountDeletionTitle: () =>
    _t(translations.confirmAccountDeletionTitle),
  confirmAccountDeletionDescription: () =>
    _t(translations.confirmAccountDeletionDescription),
  accountDeletionSuccess: () => _t(translations.accountDeletionSuccess),
  Cancel: () => _t(translations.Cancel),
  Confirm: () => _t(translations.Confirm),
  Delete_Account: () => _t(translations.Delete_Account),
  Close: () => _t(translations.Close),
  accountSuccessfullyDeleted: () => _t(translations.accountSuccessfullyDeleted),
  otpVerification: () => _t(translations.otpVerification),
  enterOtp: () => _t(translations.enterOtp),
  enterOtpPlaceholder: () => _t(translations.enterOtpPlaceholder),
  otpError: () => _t(translations.otpError),
  verify: () => _t(translations.verify),
  cancel: () => _t(translations.cancel),
  otpVerifiedSuccess: () => _t(translations.otpVerifiedSuccess),
  otpVerificationFailed: () => _t(translations.otpVerificationFailed),
  failedToSendSms: () => _t(translations.failedToSendSms),
  phoneLoginFailed: () => _t(translations.phoneLoginFailed),
  otpCodeSentSuccess: () => _t(translations.otpCodeSentSuccess),
};
